// include all
//@import "./node_modules/bootstrap/scss/bootstrap";
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$davys-gray:                        #51515D;
$light-red:                         #FF8787;
$primary:                           $davys-gray;
$background-color:                  rgba(245, 245, 245, 1);

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

$navbar-padding-y:                  $spacer * 1;
$navbar-light-color:                rgba($davys-gray, .9);
$navbar-light-hover-color:          rgba($light-red, .7);
$navbar-light-active-color:         rgba($davys-gray, .55);
$navbar-light-disabled-color:       rgba($davys-gray, .3);

$offcanvas-vertical-height:         10vh;
$enable-responsive-font-sizes:      true;


// 4. Include any default map overrides here
$custom-colors: (
  "light-red": $light-red
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
//@import "../node_modules/bootstrap/scss/dropdown";
//@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
//@import "../node_modules/bootstrap/scss/breadcrumb";
//@import "../node_modules/bootstrap/scss/pagination";
//@import "../node_modules/bootstrap/scss/badge";
//@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
//@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
//@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
//@import "../node_modules/bootstrap/scss/tooltip";
//@import "../node_modules/bootstrap/scss/popover";
//@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
//@import "../node_modules/bootstrap/scss/offcanvas";
//@import "../node_modules/bootstrap/scss/placeholders";

@import "../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here

/********************************************************************************************/
/*
/*  common styles
 */
/********************************************************************************************/
$cta-color: darken($light-red, 3%);
.btn-cta {
  @include button-variant($cta-color, white);
  --bs-btn-color: white;
  --bs-btn-border-color: #{$cta-color};
  --bs-btn-hover-color: white;
  --bs-btn-hover-border-color: #{$cta-color};
  --bs-btn-active-color: white;
  --bs-btn-active-border-color: #{$cta-color};
  @include font-size(1.25rem);
  font-weight: 500;
}
.btn-outline-cta {
  @include button-outline-variant($cta-color, white);
  --bs-btn-active-color: white;
  @include font-size(1.25rem);
  font-weight: 500;
}

.btn-light-red {
  --bs-btn-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
  --bs-btn-disabled-color: white;
}
.btn-outline-light-red {
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
}

html, body {
  background-color: $background-color;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p {
  line-height: 1.75rem;
}

main {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.native-fonts {
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fonts {
  font-family: 'Poppins', $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-title {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-sub-title {
  font-weight: 700;
  color: $primary;
  text-align: center;
  @include media-breakpoint-up(md) {
    margin-top: 8rem;
    margin-bottom: 4rem;
  }
  @include media-breakpoint-down(md) {
    margin-top: 6rem;
    margin-bottom: 3rem;
  }
}

.card {
  --bs-card-color: #{$primary};
}

footer {
  color: $primary;
  margin-top: 8rem;

  p, li {
    font-weight: 300;
  }
  .copyright {
    text-align: center;
    p {
      margin-bottom: 1rem;
    }
  }
  a {
    text-decoration: none !important;
    &:hover {
      color: $light-red;
      text-decoration: underline !important;
    }
  }
}

#navbar {
  background-color: rgba(245, 245, 245, 0.94);
  a.navbar-brand img {
    width: auto;
    height: 2.5rem;
  }
}

// contact component
.contact-block {
  margin-left: 0;
  margin-right: 0;
  background-size: cover;
  @media (max-width: 576px) {
    background-image: url("/img/exam.contact.background.596.png");
    background-image: -webkit-image-set(
        url("/img/exam.contact.background.596.webp") type("image/webp"),
        url("/img/exam.contact.background.596.png") type("image/png")
    );
    background-image: image-set(
        url("/img/exam.contact.background.596.webp") type("image/webp"),
        url("/img/exam.contact.background.596.png") type("image/png")
    );
  }
  @media (min-width: 576px) and (max-width: 768px) {
    background-image: url("/img/exam.contact.background.794.png");
    background-image: -webkit-image-set(
        url("/img/exam.contact.background.794.webp") type("image/webp"),
        url("/img/exam.contact.background.794.png") type("image/png")
    );
    background-image: image-set(
        url("/img/exam.contact.background.794.webp") type("image/webp"),
        url("/img/exam.contact.background.794.png") type("image/png")
    );
  }
  @media (min-width: 768px) and (max-width: 992px) {
    background-image: url("/img/exam.contact.background.998.png");
    background-image: -webkit-image-set(
        url("/img/exam.contact.background.998.webp") type("image/webp"),
        url("/img/exam.contact.background.998.png") type("image/png")
    );
    background-image: image-set(
        url("/img/exam.contact.background.998.webp") type("image/webp"),
        url("/img/exam.contact.background.998.png") type("image/png")
    );
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    background-image: url("/img/exam.contact.background.1200.png");
    background-image: -webkit-image-set(
        url("/img/exam.contact.background.1200.webp") type("image/webp"),
        url("/img/exam.contact.background.1200.png") type("image/png")
    );
    background-image: image-set(
        url("/img/exam.contact.background.1200.webp") type("image/webp"),
        url("/img/exam.contact.background.1200.png") type("image/png")
    );
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    background-image: url("/img/exam.contact.background.1440.png");
    background-image: -webkit-image-set(
        url("/img/exam.contact.background.1440.webp") type("image/webp"),
        url("/img/exam.contact.background.1440.png") type("image/png")
    );
    background-image: image-set(
        url("/img/exam.contact.background.1440.webp") type("image/webp"),
        url("/img/exam.contact.background.1440.png") type("image/png")
    );
  }
  @media (min-width: 1400px) and (max-width: 1680px) {
    background-image: url("/img/exam.contact.background.1680.png");
    background-image: -webkit-image-set(
        url("/img/exam.contact.background.1680.webp") type("image/webp"),
        url("/img/exam.contact.background.1680.png") type("image/png")
    );
    background-image: image-set(
        url("/img/exam.contact.background.1680.webp") type("image/webp"),
        url("/img/exam.contact.background.1680.png") type("image/png")
    );
  }
  @media (min-width: 1680px) {
    background-image: url("/img/exam.contact.background.1920.png");
    background-image: -webkit-image-set(
        url("/img/exam.contact.background.1920.webp") type("image/webp"),
        url("/img/exam.contact.background.1920.png") type("image/png")
    );
    background-image: image-set(
        url("/img/exam.contact.background.1920.webp") type("image/webp"),
        url("/img/exam.contact.background.1920.png") type("image/png")
    );
  }

  .card {
    h3, .h3 {
      color: white !important;
    }
    border-radius: 0 !important;
    --bs-card-bg: transparent;
  }

  .form-control {
    color: white !important;
    border-color: lighten($light-red, 5%) !important;
    background-color: transparent !important;
  }

  label {
    font-weight: 300;
    color: $light-red !important;
  }

  label::after {
    background-color: transparent !important;
  }

  .invalid-feedback {
    text-align: left;
  }

  textarea {
    height: 9rem !important;
  }

  .chat-btn {
    width: auto;
    height: 3.5rem;
    border-radius: 1rem !important;
    box-shadow: 8px 8px 8px black !important;
  }
}

// other utilities
a.external-link-indicator {
  &::after {
    content: "";
    width: 11px;
    height: 11px;
    margin-left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
  }
}

/********************************************************************************************/
/*
/*  home page's styles
 */
/********************************************************************************************/
#home-page {
  #headline {
    .img-wrapper {
      position: relative;
    }

    .img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
      transform: translateY(25%);
    }

    .img-overlay:before {
      content: ' ';
      display: block;
      /* adjust 'height' to position overlay content vertically */
      height: 50%;
    }
  }

  .section-title {
    @include media-breakpoint-up(md) {
      margin-top: 8rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 5rem;
    }

    h2 {
      @include font-size(2.5rem);
      color: $primary;
      font-weight: 700;
      //text-align: center;
      display: inline-block;
    }
    .progress {
      --bs-progress-bg: transparent;
      --bs-progress-border-radius: 0;
      --bs-progress-height: 0.5rem;
    }
    .progress-bar {
      width: 0;
    }
    .animate-progress {
      animation: move-progress 850ms linear forwards;
    }
    @keyframes move-progress {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
    p {
      margin-top: 1rem;
    }
  }

  #whyus {
    .card {
      border-radius: 0 !important;
    }
    .card-body {
      h3 {
        color: $primary;
        font-weight: 500;
      }
    }
  }

  #howitworks {
    .circle {
      position: relative;
      display: block;
      background-color: transparent;
      color: $primary;
      text-align: center;
      max-width: 48px;
      min-width: 16px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .circle:after {
      display: block;
      padding-bottom: 100%;
      width: 100%;
      height: 0;
      border-radius: 50%;
      box-shadow: 0 1rem 3rem rgba($primary, .175);
      background-color: $primary;
      content: "";
    }

    .circle__inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .circle__wrapper {
      display: table;
      width: 100%;
      height: 100%;
    }

    .circle__content {
      display: table-cell;
      //padding: 1em;
      vertical-align: middle;
      @include font-size(2rem);
      color: #fff;
    }
  }

  #articles {
    .card:hover {
      --bs-card-border-color: #{$light-red};
      box-shadow: 0 0 10px rgba($light-red, 0.5);
    }
  }
}

/********************************************************************************************/
/*
/*  services page's styles
 */
/********************************************************************************************/
#services-page {
  img.svg-icon {
    width: 4.75rem;
    height: 4.75rem;
  }

  .services-advantages {
    .card-title {
      color: $primary;
      font-weight: 500;
    }
    .card-text {
      line-height: var(--bs-body-line-height);
    }
  }

  .services-popular {
    .services-index {
      a {
        margin-right: 1rem;
        margin-bottom: 0.5rem;
      }
    }
    // from https://stackoverflow.com/questions/57038016/how-to-achieve-a-responsive-ribbon-shape-in-css
    .ribbon-title {
      border-left: 0;
      position:relative;
      display: inline-block;
      width: calc(100% + 1.75rem);
      overflow:hidden;
      z-index:0;

      &::before, &::after{
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        height: 50%;
        background: $primary;
      }
      &::before {
        top: 0;
        transform: skew(-45deg);
        transform-origin: top;
      }
      &::after {
        bottom: 0;
        transform: skew(45deg);
        transform-origin: bottom;
      }

      h3 {
        color: white;
        margin: 0 0 0 1rem;

        &::before {
          position: absolute;
          left: 0;
          content: "";
          height: 100%;
          width: 0.5rem;
          background-color: $light-red;
        }
      }
    }

    .card {
      height: 100%;
      picture {
        display: inline-block;
        width: 50%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        -ms-flex-direction: column;

        li {
          line-height: 2rem;

          &:hover {
            &::marker {
              color: $light-red;
            }
          }
        }
      }

      a.card-link {
        &:hover {
          color: $light-red;
        }
      }
    }

    .services-row {
      @include media-breakpoint-up(md) {
        margin-bottom: 5rem;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 3rem;
      }
    }
  }
}

/********************************************************************************************/
/*
/*  faq page's styles
 */
/********************************************************************************************/
#faq-page {
  .faq-content {
    @include media-breakpoint-up(md) {
      margin-top: 8rem;
      margin-bottom: 4rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 6rem;
      margin-bottom: 3rem;
    }

    h2 button {
      font-weight: bold;
    }
  }
}

/********************************************************************************************/
/*
/*  blog page's styles
 */
/********************************************************************************************/
#blog-page {
  .sub-title-description {
    @include font-size(20px);
    color: $primary;
    text-align: center;
    @include media-breakpoint-up(md) {
      margin-top: -3rem;
      margin-bottom: 4rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: -2rem;
      margin-bottom: 3rem;
    }
  }
  .card {
    --bs-border-radius: 0;
    --bs-card-bg: #{$background-color};
  }
  h3 {
    @include font-size(24px);
  }
}

/********************************************************************************************/
/*
/*  cert page's styles
 */
/********************************************************************************************/
.cert-page {
  .split {
    font-weight: normal;
    color: $light-red;
  }

  .header {
    position:relative;
    text-align:center;
    background: linear-gradient(60deg, darken($primary, 25%) 0%, $primary 100%);
    color:white;
  }

  .inner-header {
    height:50vh;
    width:100%;
    margin: 0;
    padding: 0;

    h1 strong {
      color: $light-red;
    }
  }

  .flex { /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .waves {
    position:relative;
    width: 100%;
    height:15vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:100px;
    max-height:150px;
  }

  .content {
    position:relative;
    height:20vh;
    text-align:center;
    background-color: white;
  }

  /* Animation */

  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px,0,0);
    }
    100% {
      transform: translate3d(85px,0,0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height:40px;
      min-height:40px;
    }
    .content {
      height:30vh;
    }
  }

  .cert-intro {
    .col-md-2 {
      @include media-breakpoint-down(md) {
        text-align: center !important;
      }
    }
    img {
      background-color: white;
      width: 150px !important;
      height: auto;
    }
    dl {
      padding: .5rem;
    }
    dt {
      float: left;
      clear: left;
      text-align: right;
      font-weight: 700;
      color: $primary;
    }
    dt::after {
      content: ":";
    }
    dd {
      margin: 0 0 0 150px;
      padding: 0 0 0.5em 0;
    }
  }

  .article-link-list {
    ul {
      display: block;
      margin-left: -10px;
    }

    ul li {
      display: block;
      position: relative;
    }

    ul li:not(:last-child) {
      margin-bottom: 16px;
    }

    ul li:before {
      content: "";
      position: absolute;
      top: 1.2em;
      left: -30px;
      margin-top: -.9em;
      background: $light-red;
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }

    ul li a:hover {
      color: $light-red !important;
      text-decoration-color: $light-red !important;
      -webkit-text-decoration-color: $light-red !important;
      -moz-text-decoration-color: $light-red !important;
    }
  }
}

/********************************************************************************************/
/*
/*  article page's styles
 */
/********************************************************************************************/
.article-page {
  article {
    @include media-breakpoint-down(md){
      max-width: 768px !important;
    }
    @include media-breakpoint-up(md){
      max-width: 975px !important;
    }
    @include media-breakpoint-up(xxl){
      max-width: 1125px !important;
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .img-pie {
      @include media-breakpoint-up(md){
        max-width: 60vw;
      }
      @include media-breakpoint-up(xxl){
        max-width: 50vw;
      }
    }

    h1 {
      font-size: rfs-value(42px);
      font-weight: 700 !important;
      line-height: 3.25rem !important;
      letter-spacing: -0.016em;
    }
    h2 {
      font-size: rfs-value(24px);
      font-weight: 700 !important;
      line-height: 1.875rem !important;
      margin-top: 1.95em !important;
    }
    h3 {
      font-size: rfs-value(20px);
      font-weight: 700 !important;
      line-height: 1.5rem !important;
      margin-top: 1.72em !important;
    }
    p, ul, ol {
      font-size: rfs-value(20px);
      line-height: 2rem !important;
      font-weight: 400;
      margin-top: 0.94em !important;
    }
    li {
      margin-top: 2.14em;
    }
    .blog-share-icon {
      cursor: pointer;
      margin-right: 2rem;
    }
  }
}

